<template>
  <div class="nightSchool">
    <van-dropdown-menu active-color="#1989fa">
      <van-dropdown-item title="课程类型" ref="courseTypeRef">
        <van-checkbox-group v-model="courseType">
          <van-cell-group>
            <van-cell v-for="(check, index) in courseTypeOptions" :key="'kclxdx' + index" :title="check.text" clickable @click="$refs.kclxRef[index].toggle()">
              <template #right-icon>
                <van-checkbox :name="check.value" shape="square" ref="kclxRef" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
        <div class="sx-btn-box">
          <van-button type="default" size="small" @click="$refs.courseTypeRef.toggle(false)">取 消</van-button>
          <van-button type="info" size="small" @click="courseTypeSxOnClick">确 定</van-button>
        </div>
      </van-dropdown-item>
      <van-dropdown-item title="适用对象" ref="fitApplyPersonRef">
        <van-checkbox-group v-model="fitApplyPerson">
          <van-cell-group>
            <van-cell
              v-for="(check, index) in fitApplyPersonOptions"
              :key="'sydxdx' + index"
              :title="check.text"
              clickable
              @click="$refs.sydxRef[index].toggle()"
            >
              <template #right-icon>
                <van-checkbox :name="check.value" shape="square" ref="sydxRef" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
        <div class="sx-btn-box">
          <van-button type="default" size="small" @click="$refs.fitApplyPersonRef.toggle(false)">取 消</van-button>
          <van-button type="info" size="small" @click="fitApplyPersonSxOnClick">确 定</van-button>
        </div>
      </van-dropdown-item>
    </van-dropdown-menu>
    <div class="all-course-list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        :immediate-check="false"
        @load="getList"
      >
        <all-course-card v-for="(item, index) in allCourseList" :key="'sykckp' + index" :detail="item" :currentDate="currentDate" />
      </van-list>
    </div>
    <v-tabbar :active="0" />
  </div>
</template>

<script>
import vTabbar from '../components/v-tabbar.vue';
import allCourseCard from '../components/allCourseCard.vue';
import { setFitApplyPersonOps } from '../map.js';
import { getAllCourseListUrl, getCourseTypeUrl } from '../api.js';
import Vue from 'vue';
import { Checkbox, CheckboxGroup, Cell, CellGroup, Button } from 'vant';
import { mapState } from 'vuex';

Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Button);

export default {
  name: 'nightSchool',
  components: { vTabbar, allCourseCard },
  data() {
    return {
      courseTypeOptions: [],
      fitApplyPersonOptions: setFitApplyPersonOps(),
      total: 0,
      queryForm: {
        courseType: void 0,
        fitApplyPerson: void 0,
        curPage: 1,
        pageSize: 10
      },
      courseType: [],
      fitApplyPerson: [],
      allCourseList: [],
      loading: false,
      finished: false,
      error: false,
      timer: null,
      currentDate: new Date()
    };
  },
  computed: {
    ...mapState(['userId'])
  },
  created() {
    this.$axios
      .get(getCourseTypeUrl, {})
      .then(res => {
        if (res && res.code === 200) {
          res.data = Array.isArray(res.data) ? res.data : [];
          this.courseTypeOptions = res.data.map(item => ({ text: item.courseType, value: item.id }));
        }
      })
      .catch(() => {});
    this.getList();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    satrtInterval() {
      if (this.timer) clearImmediate(this.timer);
      this.timer = setInterval(() => {
        this.currentDate = new Date();
      }, 1000);
    },
    getList() {
      this.loading = true;
      this.finished = false;
      Object.assign(this.queryForm, {
        courseType: this.courseType.join(',') || void 0,
        fitApplyPerson: this.fitApplyPerson.join(',') || void 0,
        userId: this.userId
      });
      this.$axios
        .get(getAllCourseListUrl, { params: this.queryForm })
        .then(res => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            res.data.records = Array.isArray(res.data.records) ? res.data.records : [];
            res.data.records.forEach(item => {
              item.fitApplyPersonList = item.fitApplyPerson.split(',');
              item.courseImageUrl = item.coursePictureUrl.split('|').filter(item => item)[0] || '';
            });
            this.allCourseList.push(...res.data.records);
            this.queryForm.curPage++;
            this.total = res.data.total;
            this.loading = false;
            if (this.allCourseList.length >= this.total) {
              this.finished = true;
            }
            this.satrtInterval();
          } else {
            this.loading = false;
            this.error = true;
          }
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },
    courseTypeSxOnClick() {
      this.$refs.courseTypeRef.toggle(false);
      this.queryForm.curPage = 1;
      this.allCourseList = [];
      this.getList();
    },
    fitApplyPersonSxOnClick() {
      this.$refs.fitApplyPersonRef.toggle(false);
      this.queryForm.curPage = 1;
      this.allCourseList = [];
      this.getList();
    }
  }
};
</script>

<style lang="less" scoped>
.nightSchool {
  box-sizing: border-box;
  height: 100%;
  height: calc(100% - constant(safe-area-inset-bottom));
  height: calc(100% - env(safe-area-inset-bottom));
  padding-top: 96px;
  padding-bottom: 96px;
  background-color: #fafafa;
  .all-course-list {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding: 20px 32px;
    background-color: #fafafa;
    overflow-x: hidden;
    overflow-y: auto;
    .all-course-card {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
.sx-btn-box {
  box-sizing: border-box;
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .van-button {
    width: calc(50% - 20px);
  }
}

.van-dropdown-menu {
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 0px;
}
</style>
