<template>
  <div class="all-course-card" @click="cardOnClick">
    <div class="image-text-box">
      <div class="image-box">
        <img v-if="detail.courseImageUrl" class="kc-image" :src="detail.courseImageUrl" />
        <img v-else class="kc-image" src="../img/kctp_zw.png" />
      </div>
      <div class="text-info-box">
        <div class="title-box">
          <div class="title-text">{{ detail.courseName }}</div>
          <van-tag color="#E02020" size="large" plain style="background-color: rgba(224, 32, 32, 0.1)">{{
            fitApplyPersonMap[detail.fitApplyPersonList[0]]
          }}</van-tag>
        </div>
        <div class="class-time-list">
          <div class="class-time-item" v-for="(item, index) in detail.courseSettingList" :key="'kksj' + index">
            {{ getDayWeekMonth(item) }}
          </div>
        </div>
        <van-tag color="#367BF4" size="large" plain style="background-color: rgba(54, 123, 244, 0.1)">{{ detail.teacher }}</van-tag>
        <div class="limit-people-number-text">
          {{ detail.isAstrictPersons === 1 ? detail.minPerson + '人开班，' + detail.maxPerson + '人满班' : '不限人数' }}，已报{{ detail.applyUserCount }}人
        </div>
        <div class="limit-people-number-bar">
          <div v-if="detail.isAstrictPersons === 1" class="lower-bar" :style="{ width: (detail.applyUserCount / detail.maxPerson) * 100 + '%' }"></div>
          <div v-if="detail.isAstrictPersons === 1" class="value-line" :style="{ left: (detail.minPerson / detail.maxPerson) * 100 + '%' }"></div>
        </div>
      </div>
    </div>
    <div class="card-foter">
      <div class="create-course-period">开课周期：{{ detail.courseOpenStart }} ~ {{ detail.courseOpenEnd }}</div>
      <div v-if="ybmShow" class="sign-up-button ybm" @click.stop="ybmOnClick">已报名</div>
      <div v-if="wdbmsjShow" class="sign-up-button button-disabled">未开始报名</div>
      <div v-if="ljbmShow" class="sign-up-button ljbm" @click.stop="ljbmOnClick">立即报名</div>
      <div v-if="ymyShow" class="sign-up-button button-disabled">满班</div>
      <div v-if="yjzbmShow" class="sign-up-button button-disabled">截止报名</div>
    </div>
  </div>
</template>

<script>
import { fitApplyPersonMap, dayWeekMonthMap, getDayWeekMonth } from '../map.js';
import { toRegister } from '@/utils/common.js';
import Vue from 'vue';
import { Tag } from 'vant';

Vue.use(Tag);

export default {
  name: 'allCourseCard',
  props: {
    detail: {
      type: Object,
      default: () => ({
        fitApplyPersonList: [],
        courseSettingList: []
      })
    },
    currentDate: {
      type: Date,
      default: new Date()
    }
  },
  data() {
    return {
      fitApplyPersonMap,
      dayWeekMonthMap
    };
  },
  computed: {
    ljbmShow() {
      return (
        this.detail.isApply <= 0 &&
        new Date(this.detail.applyDateStart).getTime() < this.currentDate.getTime() &&
        this.currentDate.getTime() < new Date(this.detail.applyDateEnd).getTime() &&
        ((this.detail.isAstrictPersons === 1 && this.detail.applyUserCount < this.detail.maxPerson) || this.detail.isAstrictPersons === 0)
      );
    },
    ybmShow() {
      return this.detail.isApply > 0;
    },
    ymyShow() {
      return (
        this.detail.isApply <= 0 &&
        new Date(this.detail.applyDateStart).getTime() < this.currentDate.getTime() &&
        this.currentDate.getTime() < new Date(this.detail.applyDateEnd).getTime() &&
        this.detail.isAstrictPersons === 1 &&
        this.detail.applyUserCount >= this.detail.maxPerson
      );
    },
    yjzbmShow() {
      return this.detail.isApply <= 0 && this.currentDate.getTime() > new Date(this.detail.applyDateEnd).getTime();
    },
    wdbmsjShow() {
      return this.detail.isApply <= 0 && this.currentDate.getTime() < new Date(this.detail.applyDateStart).getTime();
    }
  },
  created() {},
  methods: {
    cardOnClick() {
      this.$router.push({
        name: 'allCourseDetail',
        query: {
          id: this.detail.id
        }
      });
    },
    async ljbmOnClick() {
      if (!this.ljbmShow) return;
      if (await toRegister(this.$route.path, this.$route.query, '热门活动报名')) {
        this.$router.push({
          name: 'signUpConfirm',
          query: {
            id: this.detail.id
          }
        });
      }
    },
    async ybmOnClick() {
      if (!this.ybmShow) return;
      if (await toRegister(this.$route.path, this.$route.query, '热门活动报名')) {
        this.$router.push({
          name: 'myCourseOrderDetail',
          query: {
            id: this.detail.applyId
          }
        });
      }
    },
    getDayWeekMonth
  }
};
</script>

<style lang="less" scoped>
.all-course-card {
  box-sizing: border-box;
  padding: 16px 18px 0px 18px;
  background: #ffffff;
  box-shadow: 0px 4px 32px 0px rgba(66, 114, 153, 0.1);
  border-radius: 16px;
  .image-text-box {
    box-sizing: border-box;
    display: flex;
    .image-box {
      box-sizing: border-box;
      flex-shrink: 0;
      width: 250px;
      height: 246px;
      border-radius: 16px;
      margin-right: 18px;
      .kc-image {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border-radius: 16px;
        object-fit: cover;
      }
    }
    .text-info-box {
      box-sizing: border-box;
      flex-grow: 1;
      width: calc(100% - 268px);
      .title-box {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        .title-text {
          box-sizing: border-box;
          flex-grow: 1;
          max-width: calc(100% - 100px);
          font-size: 32px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 46px;
          font-weight: 700;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .van-tag {
          flex-shrink: 0;
        }
      }
      .class-time-list {
        box-sizing: border-box;
        margin: 6px 0px;
        color: rgba(0, 0, 0, 0.5);
        font-size: 24px;
        line-height: 36px;
      }
      .limit-people-number-text {
        box-sizing: border-box;
        margin-top: 15px;
        color: rgba(0, 0, 0, 0.5);
        font-size: 24px;
        line-height: 24px;
      }
      .limit-people-number-bar {
        box-sizing: border-box;
        margin-top: 4px;
        width: 100%;
        height: 16px;
        border-radius: 8px;
        background-color: #e6e6e6;
        position: relative;
        .lower-bar {
          box-sizing: border-box;
          position: absolute;
          left: 0px;
          top: 0px;
          border-radius: 8px;
          background-color: #367bf4;
          height: 100%;
          max-width: 100%;
        }
        .value-line {
          box-sizing: border-box;
          width: 2px;
          height: 100%;
          position: absolute;
          top: 0px;
          background-color: #fcaa3e;
        }
      }
    }
  }
  .card-foter {
    box-sizing: border-box;
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .create-course-period {
      box-sizing: border-box;
      flex-shrink: 0;
      font-size: 24px;
      color: #367bf4;
      line-height: 30px;
    }
    .sign-up-button {
      box-sizing: border-box;
      flex-shrink: 0px;
      margin-right: -18px;
      box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
      border-radius: 16px 0px 16px 0px;
      width: 194px;
      height: 62px;
      font-size: 26px;
      line-height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 2px;
      &.ybm {
        background: linear-gradient(180deg, #ffc055 0%, #fe9615 100%);
        color: #ffffff;
      }
      &.ljbm {
        background: linear-gradient(180deg, #73a8ff 0%, #2e7bff 100%);
        color: #ffffff;
      }
      &.button-disabled {
        background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
        color: #a8a8a8;
      }
    }
  }
}
</style>
